.Started
    .container
        display: flex
        flex-direction: column
        gap: 2rem
        background: url('two.jpg')
        padding: 4rem 1rem
        align-items: center
        background-repeat: no-repeat
        background-size: cover
        justify-content: center
        text-align: center
        color: white
        position: relative
        &::after
            content: ''
            background: rgb(black, .5)
            width: 100%
            height: 100%
            top: 0
            left: 0
            position: absolute
            z-index: 4


        .header
            font-size: 30px
            font-weight: 800
            @media (max-width: 1000px) 
            font-size: 25px
            z-index: 5
            @media (max-width: 500px) 
                font-size: 23px
                
        .text
            color: whitesmoke
            z-index: 5
            font-style: italic
            font-size: 18px
            letter-spacing: 1px
            @media (max-width: 500px)
                font-size: 14px 
        .button
            padding: 1rem 2.5rem
            z-index: 5
            background: orange
            width: fit-content
            text-transform: uppercase
            transition: 500ms
            cursor: pointer
            border: 3px solid orange
            @media (max-width: 500px) 
                padding: .7rem 1.5rem
                font-size: 14px
            &:hover
                transition: 500ms
                padding: 1rem 3rem
                background: transparent
        