$purple: #e102f5;
$deeppurple: #760980;
$orange: #f57a07;
$brightorange: rgb(255, 89, 0);
$deeppink: #d40663;
$yellow: #ddc223;
$hotpink: rgb(250, 56, 153);
$blue: #2385c2;

.Card{
        width: 100%;
        padding: 3rem 1rem;
        background: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
        .cards{
        display: grid;
        grid-auto-flow: column;
        gap: 2rem;
        padding: 3rem 1rem 2rem;
        scroll-padding-inline: 1rem;
        justify-content: center; 
        text-transform: uppercase;
        .card{
            padding: 1.5rem 2rem;
            box-shadow: 3px 3px 10px grey;
            transition: 200ms;
            border-radius: 5px;
            width: 270px;
            color: white;
            scroll-snap-align: start;
            background: #656565;
            &:hover{
                background: transparent;
            }
            &>:nth-child(1){
                font-size: 30px;
            }
            &>:nth-child(2){
                font-size: 15px;
            }
            &>:nth-child(3){
                font-size: 25px;
                font-weight: 600;
            }
            &>:nth-child(4){
                font-size: 14px;
                text-transform: uppercase;
            }
            &>:nth-child(5){
                font-size: 15px;
                transition: 500ms;
                line-height: 1.5rem;

                @media (max-width: 1150px) {
                    font-size: 14px;
                }
                @media (max-width: 350px) {
                    font-size: 13px;
                }
            }
            &>:last-child{
                height: 40px;
                background: white;
                font-weight: bold;
                color: #f5700a;
                border-radius: 3px;
                font-size: 11px   
            }
            @media (min-width: 1000px) {
                width: 270px;
            }
            @media (max-width: 350px) {
                width: 240px;
            }
        }

        @media (max-width: 1250px) {
            padding: 2rem 1rem;
            overflow-x: auto;
            width: 100%;
            justify-content: start; 
            gap: 1rem;
            scroll-snap-type: inline mandatory;
        }
        @media (max-height: 500px) {
        }
    }
    .top{
        height: 70px;
        font-size: 23px;
    }
}
