.Stats
    padding: 4rem 1rem

    .container
        display: flex
        flex-direction: column
        gap: 6rem
        padding: 2.5rem 1rem
        .contents
            display: grid
            grid-template-columns: 1fr 1fr 1fr
            gap: 1rem
            color: white
            text-align: center
            @media (max-width: 1000px) 
                grid-template-columns: 1fr
                gap: 2rem
            .wrapper
                display: flex
                flex-direction: column
                gap: .5rem
                &>:nth-child(1)
                    font-size: 25px
                    font-weight: 800
                    letter-spacing: 2px
                    @media (max-width: 500px)
                        font-size: 20px
                        

                &>:nth-child(2)
                    font-size: 14px

        .btn-wrapper
            display: flex
            justify-content: center
            align-items: center
            gap: 1rem
            @media (max-width: 500px)
                flex-direction: column
                gap: 1.5rem
            &>:nth-child(1)
                height: 50px
                width: 200px
                display: grid
                place-items: center
                background: transparent
                border: 2px solid orange
                cursor: pointer
                color: orange
                @media (max-width: 500px)
                    width: 180px


            &>:nth-child(2)
                color: white
            &>:nth-child(3)
                color: white
                height: 50px
                width: 200px
                display: grid
                place-items: center
                cursor: pointer
                background: orange
                border: 2px solid orange
                @media (max-width: 500px)
                    width: 180px


                
