$purple: #e102f5;
$deeppurple: #760980;
$orange: #f57a07;
$brightorange: rgb(255, 89, 0);
$deeppink: #d40663;
$yellow: #ddc223;
$hotpink: rgb(250, 56, 153);
$blue: #2385c2;
$lightgrey: lightgrey;
$grey: grey;

nav{
    display: grid;
    grid-template-columns: .8fr 2.5fr 1fr;
    height: 100px;
    background: #3b3b3b;
    .left-nav{
        .logo{
            font-size: 33px;
        }
    }
    .center-nav{
            .links{
            gap: 2rem;
            .link{
                display: flex;
                flex-direction: column;
                gap: .5rem;
                cursor: pointer;
                &>:nth-child(1){
                    font-weight: bold;
                    color: white;
                    font-size: 14px;
                }
                &>:nth-child(2){
                    color: lightgrey;
                    font-size: 13px;
                }
                .btc{
                    gap: .1rem;
                    justify-content: center;
                    &>:nth-child(1){
                        color: #f48915;
                    }
                }
            }
            @media (min-width: 1300px) {
                gap: 5rem;
            }
        }
    }
    .right-nav{
        gap: 1rem;
        &>p{
            gap: .2rem;
            padding: .5rem 1rem;
            border-radius: 3px;
            cursor: pointer;
            border-radius: 20px;
            font-weight: 600;
            transition: 1s;
            display: flex;
            align-items: center;
            &:hover{
                background: white;
                font-weight: bold;
                color: black;
            }
            &>:nth-child(1){
                font-size: 18px;
            }
            &>:nth-child(2){
                font-size: 11px;
            }
        }

        &>:nth-child(1){
            background: $deeppurple;
            color: white;
            border: 2px solid red; 
            background-color: red;
        }
        &>:nth-child(2){
            background: $deeppurple;
            color: white;
            border: 2px solid red; 
            background-color: red;
            
        }
    }

    @media (max-width: 980px) {
        display: none;
    }
}
