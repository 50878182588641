*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    border: none;
    outline: none;
    text-decoration: none;
    list-style: none;
}

.active{
    color: red !important;
}

.grid{
    display: grid !important;
}
.grid-column{
    grid-auto-flow: column !important;
}
.grid-align-start{
    align-content: start !important;
}
.grid-align-center{
    align-content: center !important;
}
.grid-align-end{
    align-content: end !important;
}
.grid-justify-start{
    justify-content: start !important;
}
.grid-justify-center{
    justify-content: center !important;
}
.grid-justify-end{
    justify-content: end !important;
}

.flex{
    display: flex !important;
}

.flex-column{
    flex-direction: column !important;
}
.flex-align-start{
    align-items: flex-start !important;
}

.flex-align-center{
    align-items: center !important;
}

.flex-align-end{
    align-items: flex-end !important;
}

.flex-justify-start{
    justify-content: flex-start !important;
}
.uppercase{
    text-transform: uppercase !important;
}

.camelcase{
    text-transform: capitalize !important;
}

.flex-justify-center{
    justify-content: center !important;
}
.none{
    display: none !important;
}

.flex-justify-start{
    justify-content: flex-end !important;
}

.vw100{
    width: 100vw !important;
}
.w100{
    width: 100% !important;
}
.vh100{
    height: 100vh !important;
}
.h100{
    height: 100% !important;
}

.gap-0p1{
    gap: .1rem !important;
}

.gap-0p2{
    gap: .5rem !important;
}

.gap-0p3{
    gap: .3rem !important;
}


.gap-0p5{
    gap: .5rem !important;
}

.gap-1{
    gap: 1rem !important;
}

.gap-1p5{
    gap: 1.5rem !important;
}

.gap-2{
    gap: 2rem !important;
}


.line-1{
    line-height: 1rem !important;
}


.line-1p2{
    line-height: 1.2rem !important;
}


.line-1p3{
    line-height: 1.3rem !important;
}


.line-1p5{
    line-height: 1.5rem !important;
}


.line-2{
    line-height: 2rem !important;
}


.line-2p5{
    line-height: 2.5rem !important;
}


.line-3{
    line-height: 3rem !important;
}

.pointer-event{
    pointer-events: none;
}

.pointer{
    cursor: pointer !important;
}

.relative{
    position: relative !important;
}

.transparent{
    background-color: transparent !important;
}

.absolute{
    position: absolute !important;
}

.fixed{
    position: fixed !important;
}

.sticky{
    position: sticky !important;
}



.white{
    color: white !important;
}

.black{
    color: black !important;
}

.red{
    color: red !important;
}

.blue{
    color: blue !important;
}

.grey{
    color: grey !important;
}

.yellow{
    color: yellow !important;
}

.green{
    color: green !important;
}

.text-center{
    text-align: center !important;
}

.five{
    font-weight: 500 !important;
}

.six{
    font-weight: 600 !important;
}

.seven{
    font-weight: 700 !important;
}

.eight{
    font-weight: 800 !important;
}

.nine{
    font-weight: 900 !important;
}
.hfit{
    height: fit-content !important;
}

.wfit{
    width: fit-content !important;
}

.absolute-center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.opacity{
    opacity: 0;
}

.hoverunderline{
    transition: 500ms !important;
}
.hoverunderline:hover{
    transition:  500ms !important;
    text-decoration: underline !important;
}


.hoverred{
    transition: 500ms !important;
}
.hoverred:hover{
    transition: 500ms !important;
    color: red !important;
}

.hoverblue{
    transition: 500ms !important;
}
.hoverblue:hover{
    transition: 500ms !important;
    color: blue !important;
}

.bgwhite{
    background-color: white !important;
}

.bgblack{
    background-color: black !important;
}
.bgred{
    background-color: red !important;
}
.bgblue{
    background-color: blue !important;
}

.bggreen{
    background-color: green !important;
}

.bgorange{
    background-color: orange;
}

.bgorangered{
    background-color: orangered;
}

.bgyellow{
    background-color: yellow !important;
}
.bggrey{
    background-color: grey !important;
}