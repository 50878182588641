.About
    padding: 4rem 1rem 4rem
    .container
        display: grid
        grid-template-columns: 1fr 1fr
        .left
        .right
            display: flex
            flex-direction: column
            gap: 2rem
            color: white


            .header
                font-size: 20px
                font-weight: 700

            .text1
                letter-spacing: 1px
                line-height: 1.5rem

            .wrapper
                display: flex
                font-size: 15px
                font-weight: 600
                gap: 1rem
                @media (max-width: 1000px) 
                    font-size: 14px
                    font-weight: 500
                    justify-self: center
                    align-self: center

                &>p
                    cursor: pointer

                &>:nth-child(1)
                    border-right: 2px solid white
                    padding-right: 1rem
                    color: orange

                &>:nth-child(2)
                    border-right: 2px solid white
                    padding-right: 1rem
                    
                &>:nth-child(3)

            .text2
                letter-spacing: 1px
                line-height: 1.5rem
            .button
                width: fit-content
                padding: .7rem 2rem
                cursor: pointer
                background: red
                @media (max-width: 1000px) 
                    align-self: center
                

        @media (max-width: 1000px) 
            grid-template-columns: 1fr
            gap: 1.5rem

            @media (max-width: 1000px) 
                text-align: center

    @media (max-width: 1000px)
        padding: 1rem 1rem 2rem
    @media (max-width: 330px)
        padding: 1rem .5rem 2rem


