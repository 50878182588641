.Expert{
    padding: 4rem 1rem 4rem;
    background-color: black;
    div{
        padding: 0 1rem;
        justify-content: center;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        div{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 0 1rem;
            align-items: center;
            text-align: center;
            color: white;
            .circle{
                height: 80px;
                width: 80px;
                background: white;
                border-radius: 50%;
                border: 3px solid orange;
                @media (max-width: 970px) {
                    height: 60px;
                    width: 60px;
                }
            }
            &>:nth-child(2){
                font-weight: 700;
                font-size: 17px;
            }
            &>:nth-child(3){
                width: 28ch;
            }
        }
        @media (max-width: 740px) {
            grid-template-columns: 1fr;
            gap: 2rem;
            padding: 2rem 1rem;
        }
    }
}