.Line
    .container
        padding: 1rem 1rem 2.5rem
        text-align: center
        display: flex
        align-items: center
        justify-content: center
        gap: 1rem
        color: white
        .line
            width: 30px
            height: 3px
            background: orange
            border-radius: 50px
