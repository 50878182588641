.Contact{
        padding: 5rem 3rem;
    .container{
        padding-top: 1.5rem;
        gap: 2rem;
        .cont{
            color: white;
            h1{
                border-top: 2px solid #f48915;
            }
        }
        @media (max-width: 820px) {
            flex-direction: column;
        }
    }
    @media (max-width: 1000px) {
        padding: 2rem;
    }
}