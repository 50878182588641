.Footer
    padding: 8rem 1rem 1rem
    background: #17222c
    .cover
        display: grid
        grid-template-columns: repeat(auto-fit, minmax(200px, 250px))
        justify-content: center
        gap: 2rem
        .cont
            display: flex
            flex-direction: column
            padding: 1rem 0 1rem
            gap: 1rem
            padding: 1rem
            &>:nth-child(1)
                font-weight: 600
                font-size: 20px
                margin-bottom: 1rem
                color: white
            &>:nth-child(2)
                font-size: 15px
                color: white
            &>:nth-child(3)
                font-size: 15px
                color: white
            ul
                display: flex
                flex-direction: column
                gap: 1rem
                a
                    color: white
                    font-size: 17px
                
            .wrapper
                display: flex
                gap: 1rem
                align-items: center

            .social
                display: flex
                justify-content: start
                gap: 1rem
                margin-bottom: 1.5rem
                align-items: center
       
    .copy
        height: 70px
        display: flex
        align-items: center
        margin-top: 2rem
        justify-content: center
        padding-top: 1rem
        border-top: 2px solid white
        text-align: center
        color: white
    @media (max-width: 1000px) 
        padding: 5rem 1rem    
