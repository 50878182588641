 .Slider{
    .container{
        height: calc(100vh - 155px);
        background: url('./one.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        &::after{
            content: '';
            height: 100%;
            width: 100%;
            background: rgb(37, 36, 36, .1);
            top: 0;
            left: 0;
            z-index: 1;
            position: absolute;
        }
        .box{
            top: 50%;
            padding: 1rem;
            left: 50%;
            transform: translate(-50%, -70%);
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            z-index: 11;
            font-size: 18px;
            align-items: center;
            span{
                color: red;
            }
            &>:nth-child(1){
                margin-bottom: 2rem;
                font-size: 40px;
                font-weight: 900;
                line-height: 4rem;
                color: white;
                
            }
            &>:nth-child(2){
                padding: 2rem 1rem;
                background: red;
                color: white;
                border-radius: 30px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 400ms;
                width: fit-content;
                font-size: 16px;
                justify-self: center;
                border: 2px solid red;
                &:hover{
                    padding: .7rem 1.5rem;
                    transition: 400ms;
                }
            }
        }
    }
 }