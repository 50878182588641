.Nav{
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 50;
    height: fit-content;
    .nav{
        border-bottom: 5px solid #f48915;
        z-index: 50;
        .links{
            gap: 2rem;
            background-color: #3b3b3b;
            height: 55px;   
            z-index: 50;
            position: relative;
            p{
                font-size: 13px;
                a{
                    color: white;
                    transition: 400ms;
                    &:hover{
                        color: red;
                    }
                }
            }
        }
        .search{
            height: 50px;
            background-color: rgb(53, 52, 52);
            margin-top: -50px;
            transition: 400ms;
            z-index: 3;
            input{
                width: 80%;
                height: 40px;
                margin: 0 auto;
            }
            ::placeholder{
                color: white;
            }
            .close{
                font-size: 23px;
                color: white;
                right: 1.5rem;
            }
        }
        .slide{
            margin-top: 0;
            height: 50px;
            background-color: rgb(53, 52, 52);
            z-index: 3;
            transition: 400ms;
        }
        @media (max-width: 980px) {
            display: none;
        }
    }
    .mobilenav{
        display: none;

        .top{
            height: 50px;
            background: #3b3b3b;
            padding-inline: .5rem;
            top: 0;
            &>:nth-child(1){
                font-size: 20px;
            }
        }
        #top{
            height: 50px;
        }
        .bottom{
            height: calc(100vh - 50px);
            background: #3b3b3b;
            top: 50px;
            transition: 500ms;
            overflow: auto;
            padding: .5rem 0;
            left: -200vw;
            div{
                width: 100%;
                padding: 0 1rem;
            }
            input{
                height: 50px;
                width: 100%;
                background: transparent;
            }
            ::placeholder{
                color: white;
            }
            a{
                color: white;
            }
        }
        .slidemobile{
            transition: 500ms;
            left: 0;
        }
        @media (max-width: 980px) {
            display: block;
        }
    }
    

    
}
